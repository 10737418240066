module.exports = {
  TEST_TYPE_VALUES: {
    ENUMERATION: "enumeration",
    IDENTIFICATION: "identification",
    MULTIPLE_CHOICE: "multiplechoice",
    MATCHING_TYPE: "matchingtype",
    ANSWERS_IN_A_BOX: "answersinabox",
    FILL_IN_THE_BLANKS: "fillintheblanks",
    TRUE_OR_FALSE: "trueorfalse",
    IMAGE: "image",
    FILE: "file",
    TEXT: "text",
  },
  TEST_TYPES: {
    AUTOMATIC: [
      { value: "enumeration", text: "Enumeration" },
      { value: "identification", text: "Identification" },
      { value: "multiplechoice", text: "Multiple Choice" },
      { value: "matchingtype", text: "Matching Type" },
      { value: "answersinabox", text: "Answers In A Box" },
      { value: "fillintheblanks", text: "Fill In The Blanks" },
      { value: "trueorfalse", text: "True or False" },
    ],
    MANUAL: [
      { value: "image", text: "Image" },
      { value: "file", text: "File" },
      { value: "text", text: "Text" },
    ],
  },
  ASSESSMENT_CLASSES: [
    {
      value: "PT",
      text: "Performance Task",
    },
    {
      value: "WW",
      text: "Written Works",
    },
  ],
};
