<template>
  <v-card class="elevation-2">
    <v-dialog width="1000px" v-model="logsDialog">
      <v-card elevation="4" v-if="selectedStudent">
        <v-card-title :class="`main-${subject.color}-bg white--text`">
          {{ getText(selectedAssessment, assessmentsOptions) }}
        </v-card-title>
        <v-card-text class="pa-4">
          <p class="mb-3">
            Name : {{ selectedStudent.firstName }}
            {{ selectedStudent.lastName }}
          </p>
          <v-card v-if="selectedStudent.hasLogs" outlined>
            <v-card-text>
              <v-row class="px-3 py-1" justify="space-between">
                <h4>Lesson : {{ selectedStudent.lesson }}</h4>
                <p>
                  Duration :
                  {{ selectedStudent.duration }}
                  minute{{ selectedStudent.duration > 1 ? "s" : "" }}
                </p>
              </v-row>
              <div class="mt-2">
                <v-chip
                  class="mr-2"
                  v-show="selectedStudent.performanceTask"
                  label
                  outlined
                  x-small
                >
                  Performance Task
                </v-chip>
                <v-chip
                  v-show="selectedStudent.writtenWork"
                  label
                  outlined
                  x-small
                >
                  Written Works
                </v-chip>
              </div>
              <p v-show="selectedStudent.attempts > 1">
                Accept
                {{
                  selectedStudent.recordHighest
                    ? "Highest Score"
                    : "Last Attempt"
                }}
              </p>
              <v-card
                class="mt-5"
                elevation="4"
                v-for="(test, testIndex) in selectedStudent.assessmentTests"
                :key="testIndex"
              >
                <v-card-title>
                  <v-row
                    class="pl-3 py-2"
                    align="start"
                    justify="space-between"
                  >
                    <h5>Test {{ testIndex + 1 }}</h5>
                    <v-item-group>
                      <v-btn
                        @click="test.expand = !test.expand"
                        text
                        :small="$vuetify.breakpoint.smAndUp ? true : false"
                        :x-small="$vuetify.breakpoint.xsOnly ? true : false"
                        :ripple="false"
                      >
                        <v-icon
                          :size="$vuetify.breakpoint.smAndUp ? '25' : '20'"
                        >
                          mdi-window-{{ test.expand ? "minimize" : "restore" }}
                        </v-icon>
                      </v-btn>
                    </v-item-group>
                  </v-row>
                </v-card-title>
                <v-card-subtitle>
                  {{ test.test ? testType(test.method, test.test) : "" }}
                </v-card-subtitle>
                <v-expand-transition>
                  <div v-show="test.expand">
                    <v-divider></v-divider>
                    <v-card-text>
                      <p>Instruction : {{ test.instruction }}</p>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <p>Total Items : {{ test.totalItems }}</p>
                        </v-col>
                        <v-col cols="12">
                          <v-row justify="start">
                            <v-col cols="12" sm="6">
                              <v-text-field
                                readonly
                                value="Random"
                                dense
                                outlined
                                hide-details
                                :background-color="`${
                                  test.random ? 'green' : 'red'
                                } lighten-4`"
                              >
                                <template slot="append">
                                  <v-icon size="20"
                                    >mdi-{{
                                      test.random ? "check" : "block-helper"
                                    }}</v-icon
                                  >
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col
                              v-show="test.test === 'multiplechoice'"
                              cols="12"
                              sm="6"
                            >
                              <v-text-field
                                readonly
                                value="Image as Choices"
                                dense
                                outlined
                                hide-details
                                :background-color="`${
                                  test.imageChoices ? 'green' : 'red'
                                } lighten-4`"
                              >
                                <template slot="append">
                                  <v-icon size="20"
                                    >mdi-{{
                                      test.imageChoices
                                        ? "check"
                                        : "block-helper"
                                    }}</v-icon
                                  >
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col
                              v-show="
                                test.test !== 'multiplechoice' &&
                                test.test !== 'matchingtype' &&
                                test.test !== 'trueorfalse'
                              "
                              cols="12"
                              sm="6"
                            >
                              <v-text-field
                                readonly
                                value="Case Sensitive"
                                dense
                                outlined
                                hide-details
                                :background-color="`${
                                  test.caseSensitivity ? 'green' : 'red'
                                } lighten-4`"
                              >
                                <template slot="append">
                                  <v-icon size="20"
                                    >mdi-{{
                                      test.caseSensitivity
                                        ? "check"
                                        : "block-helper"
                                    }}</v-icon
                                  >
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row v-show="test.questions.length > 0 && test.test">
                        <v-col
                          cols="12"
                          v-for="(question, questionIndex) in test.questions"
                          :key="questionIndex"
                        >
                          <v-card outlined>
                            <v-card-subtitle>
                              <v-row
                                class="py-2 px-3 mb-n7"
                                justify="space-between"
                              >
                                <h4 class="py-2">
                                  {{ `Question #${questionIndex + 1}` }}
                                </h4>
                                <p style="max-width: 100px">
                                  {{
                                    `Point${question.points > 1 ? "s" : ""} : ${
                                      question.points
                                    }`
                                  }}
                                </p>
                              </v-row>
                            </v-card-subtitle>
                            <v-card-text>
                              <p class="mb-3">
                                Question :
                                {{
                                  question.enumerateCount
                                    ? `Enumerate ${question.enumerateCount} ${question.question}`
                                    : question.question
                                }}
                              </p>
                              <v-card
                                v-show="question.preview"
                                class="mb-3"
                                outlined
                              >
                                <v-card-subtitle>
                                  <v-row class="pa-3" justify="space-between">
                                    Question's Image :
                                  </v-row>
                                  <v-img
                                    :src="question.preview"
                                    class="mt-3"
                                    max-height="500"
                                    contain
                                  />
                                </v-card-subtitle>
                              </v-card>
                              <div v-show="test.method === 'AUTOMATIC'">
                                <div
                                  v-if="
                                    test.test === 'matchingtype' ||
                                    test.test === 'trueorfalse'
                                  "
                                >
                                  <p v-if="test.test === 'trueorfalse'">
                                    Answer :
                                    {{
                                      selectedStudent.assessmentTests[
                                        testIndex
                                      ].questions[
                                        questionIndex
                                      ].answers[0].answer.toUpperCase()
                                    }}
                                  </p>
                                  <p v-else>
                                    Answer :
                                    {{
                                      selectedStudent.assessmentTests[testIndex]
                                        .questions[questionIndex].answers[0]
                                        .answer
                                    }}
                                  </p>
                                </div>
                                <v-card v-else outlined>
                                  <v-card-subtitle>
                                    <v-row
                                      class="pa-4"
                                      align="center"
                                      justify="space-between"
                                    >
                                      <h4>
                                        {{
                                          test.test === "multiplechoice"
                                            ? "Choices"
                                            : "Answers"
                                        }}
                                      </h4>
                                    </v-row>
                                  </v-card-subtitle>
                                  <v-card-text>
                                    <v-row
                                      v-if="test.test === 'multiplechoice'"
                                      align="center"
                                      justify="start"
                                    >
                                      <v-col
                                        cols="12"
                                        sm="4"
                                        v-for="(
                                          choice, choiceIndex
                                        ) in question.choices"
                                        :key="choiceIndex"
                                      >
                                        <v-textarea
                                          v-if="!test.imageChoices"
                                          readonly
                                          class="mb-n5"
                                          :value="choice.choice"
                                          :background-color="
                                            choice.correct
                                              ? 'green lighten-4'
                                              : ''
                                          "
                                          :hint="
                                            choice.correct
                                              ? 'Correct Answer'
                                              : ''
                                          "
                                          persistent-hint
                                          dense
                                          outlined
                                          auto-grow
                                          rows="1"
                                        >
                                          <template slot="prepend-inner">
                                            <v-icon size="25"
                                              >mdi-alpha-{{
                                                intToChar(choiceIndex)
                                              }}</v-icon
                                            >
                                          </template>
                                        </v-textarea>
                                        <v-row v-else align="start">
                                          <v-col cols="12">
                                            <v-card
                                              class="mb-3"
                                              :style="
                                                choice.correct
                                                  ? 'border: thin solid #1b5e20;'
                                                  : ''
                                              "
                                              outlined
                                            >
                                              <v-card-subtitle>
                                                <v-row
                                                  class="pa-3"
                                                  justify="space-between"
                                                >
                                                  <v-icon size="25"
                                                    >mdi-alpha-{{
                                                      intToChar(choiceIndex)
                                                    }}</v-icon
                                                  >
                                                  <v-btn
                                                    v-show="choice.correct"
                                                    style="background-color: #1b5e20 !important; color: #fff !important;"
                                                    absolute
                                                    right
                                                    shaped
                                                    x-small
                                                    disabled
                                                    text
                                                  >
                                                    Correct
                                                  </v-btn>
                                                </v-row>
                                                <v-skeleton-loader
                                                  v-if="!choice.preview"
                                                  class="mt-3"
                                                  boilerplate
                                                  type="image"
                                                  max-height="150"
                                                  max-width="250"
                                                ></v-skeleton-loader>
                                                <v-img
                                                  v-else
                                                  :src="choice.preview"
                                                  class="mt-3"
                                                  max-height="150"
                                                  contain
                                                />
                                              </v-card-subtitle>
                                            </v-card>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                    </v-row>
                                    <v-row v-else>
                                      <v-col
                                        v-for="(
                                          answer, answerIndex
                                        ) in question.answers"
                                        :key="answerIndex"
                                        cols="12"
                                      >
                                        <p>
                                          {{
                                            `Answer #${answerIndex + 1} : ${
                                              answer.answer
                                            }`
                                          }}
                                        </p>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                </v-card>
                              </div>
                              <v-card outlined class="mt-3">
                                <v-form v-model="studentPointsValid">
                                  <v-card-subtitle>
                                    Student Answers :
                                  </v-card-subtitle>
                                  <div
                                    class="mx-4"
                                    v-for="(
                                      studentAnswer, studentAnswerIndex
                                    ) in question.studentAnswers"
                                    :key="studentAnswerIndex"
                                  >
                                    <h3>
                                      Attempt # :
                                      {{ studentAnswer.attemptNo }} - Date :
                                      {{ studentAnswer.dateStart }} to
                                      {{ studentAnswer.dateEnd }}
                                    </h3>
                                    <v-card
                                      class="ma-4 pa-2"
                                      outlined
                                      v-for="(
                                        log, logIndex
                                      ) in studentAnswer.logs"
                                      :key="logIndex"
                                    >
                                      <v-card-text
                                        v-if="
                                          test.test === 'multiplechoice' &&
                                          test.imageChoices
                                        "
                                      >
                                        <p>Given Answer :</p>
                                        <div>
                                          <v-skeleton-loader
                                            v-if="!log.preview"
                                            boilerplate
                                            type="image"
                                            max-height="200"
                                            max-width="250"
                                          ></v-skeleton-loader>
                                          <v-img
                                            v-else
                                            :src="log.preview"
                                            max-height="200"
                                            contain
                                          />
                                        </div>
                                      </v-card-text>
                                      <v-card-text v-else>
                                        <div
                                          v-if="
                                            test.test === 'image' ||
                                            test.test === 'file'
                                          "
                                        >
                                          <p>Given Answer :</p>
                                          <div v-if="test.test === 'image'">
                                            <v-skeleton-loader
                                              v-if="!log.preview"
                                              boilerplate
                                              type="image"
                                              max-height="200"
                                              max-width="250"
                                            ></v-skeleton-loader>
                                            <v-img
                                              v-else
                                              :src="log.preview"
                                              max-height="200"
                                              contain
                                            />
                                          </div>
                                          <div v-else>
                                            <v-skeleton-loader
                                              class="mt-3"
                                              boilerplate
                                              type="image"
                                              height="85"
                                              width="85"
                                            ></v-skeleton-loader>
                                            <p>{{ log.answer }}</p>
                                            <v-btn
                                              class="mt-2"
                                              v-if="log.preview"
                                              @click="
                                                downloadFile(
                                                  log.preview,
                                                  log.answer
                                                )
                                              "
                                              small
                                              color="info"
                                            >
                                              <v-icon x-small
                                                >mdi-download</v-icon
                                              >
                                              Download
                                            </v-btn>
                                          </div>
                                        </div>
                                        <p v-else>
                                          Given Answer :
                                          {{ log.answer }}
                                        </p>
                                      </v-card-text>
                                      <v-card-actions>
                                        <v-text-field
                                          class="mb-n3"
                                          style="max-width: 150px"
                                          type="number"
                                          @change="
                                            setStudentPoint(
                                              testIndex,
                                              questionIndex,
                                              studentAnswerIndex,
                                              logIndex,
                                              $event
                                            )
                                          "
                                          :value="log.points"
                                          :label="`Point${
                                            log.points > 1 ? 's' : ''
                                          } : `"
                                          :rules="[
                                            validators.requiredNumberWithZero,
                                            getAllowedPoint(
                                              question.enumerateCount,
                                              question.points,
                                              log.points
                                            ),
                                          ]"
                                          dense
                                          outlined
                                        />
                                      </v-card-actions>
                                    </v-card>
                                  </div>
                                </v-form>
                              </v-card>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-card-text>
            <v-divider></v-divider>
          </v-card>
          <v-card v-else outlined>
            <v-card-text>No logs available</v-card-text>
          </v-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn dark color="red accent-4" @click="logsDialog = false">
            Close
          </v-btn>
          <v-btn
            @click="updateStudentPoints(studentPoints)"
            :dark="selectedStudent.hasLogs && studentPointsValid"
            color="green accent-4"
            :disabled="!selectedStudent.hasLogs || !studentPointsValid"
          >
            Update Score
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title>
      <v-row>
        <v-col
          :class="$vuetify.breakpoint.xs ? 'justify-center' : ''"
          class="d-flex align-center"
          cols="12"
        >
          <h2 class="mx-2">Assessment Grading</h2>
        </v-col>
        <v-col cols="12">
          <v-select
            @change="selectAssessment(subject.id, $event)"
            :value="selectedAssessment"
            class="mb-n5"
            :items="assessmentsOptions"
            dense
            label="Assessment : "
            hint="Choose assessment to display student grades"
            outlined
            autofocus
            height="50"
          />
        </v-col>
        <v-col class="d-flex align-center" cols="12" sm="6">
          <FilterSelect
            filterLabel="Sections"
            :filterOptions="sections"
            @change="filterStudentsBySection = $event"
          />
        </v-col>
        <v-col class="d-flex align-center" cols="12" sm="6">
          <SearchQuery @search="searchInputQuery = $event" />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-subtitle v-if="selectedAssessment">
      <v-btn :disabled="filteredStudents.length === 0" color="primary" small class="mx-2">
        <JsonExcel
          :data="filteredStudents"
          :fields="gradeFields"
          type="csv"
          :name="`${getText(selectedAssessment, assessmentsOptions)}.csv`"
        >
          Export Grades
          <v-icon size="20">mdi-download</v-icon>
        </JsonExcel>
      </v-btn>
    </v-card-subtitle>
    <v-progress-linear v-show="loading" indeterminate></v-progress-linear>
    <v-card-text v-if="selectedAssessment">
      <p>{{ getText(selectedAssessment, assessmentsOptions) }}</p>
      <v-data-table
        :search="searchInputQuery"
        :headers="studentTasksHeaders"
        :items="filteredStudents"
        :footer-props="{
          itemsPerPageOptions: studentsPerPage,
        }"
      >
        <template v-slot:[`item.score`]="{ item }">
          <v-chip small label outlined>
            {{
              getScore(
                item.assessments[0].studentAssessments,
                item.assessments[0].recordHighest
              )
            }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn @click="selectStudent(item)" small dark color="green accent-4">
            View Logs
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import CONSTANTS from "@/config/constants";
import notify from "@/utilities/notifications";
import SearchQuery from "@/components/SearchQuery";
import FilterSelect from "@/components/FilterSelect";
import { mapGetters } from "vuex";
import moment from "moment";
import validators from "@/helpers/validationHelper";
import JsonExcel from "vue-json-excel";

import _ from "lodash";
export default {
  metaInfo: {
    title: "Grades",
  },
  name: "AssessmentGrading",
  components: {
    SearchQuery,
    FilterSelect,
    JsonExcel
  },
  data() {
    return {
      validators: validators,
      testOptions: CONSTANTS.TEST_TYPES,
      notify: notify,
      searchInputQuery: "",
      filterStudentsBySection: "",
      studentPointsValid: false,
      studentPoints: [],
      loading: false,
      logsDialog: false,
      selectedStudent: null,
      selectedAssessment: null,
      studentsPerPage: [10, 15, 25, 50, 100],
      gradeFields: {
        "First Name": "firstName",
        "Middle Name": "middleName",
        "Last Name": "lastName",
        "Section": "sectionName",
        "Score": {
          field: "assessments",
          callback: (value) => {
            return this.getScore(value[0].studentAssessments, value[0].recordHighest);
          }
        }
      },
      studentTasksHeaders: [
        {
          text: "First Name",
          align: "start",
          sortable: true,
          value: "firstName",
        },
        {
          text: "Last Name",
          align: "start",
          sortable: true,
          value: "lastName",
        },
        {
          text: "Section",
          align: "center",
          sortable: true,
          value: "sectionName",
        },
        {
          text: "Score",
          align: "center",
          sortable: true,
          value: "score",
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      subjectData: "_subjectsModule/getSubjectTaskGrade",
    }),
    assessmentGrade() {
      return this.$store.state.assessmentModule.assessmentGrade;
    },
    assessments() {
      return this.$store.state.assessmentModule.assessments;
    },
    subject() {
      return this.$store.state.subjectsModule.subject;
    },
    assessmentsOptions() {
      let assessmentsOptions = [];

      if (this.assessments.length > 0) {
        assessmentsOptions = this.assessments.map((assessment) => {
          return {
            text: assessment.title.concat(" - Quarter ", assessment.quarter),
            value: assessment.id,
          };
        });
      }

      return assessmentsOptions;
    },
    filteredStudents() {
      let studentsEnrolled = [],
        filteredStudents = [],
        sections =
          this.subjectData.sections?.length > 0
            ? [...this.subjectData.sections]
            : [],
        irregularStudents =
          this.subjectData.irregular?.length > 0
            ? [...this.subjectData.irregular]
            : [];

      if (sections.length > 0) {
        sections.map((section) => {
          section.students?.map((student) => {
            studentsEnrolled.push({
              ...student,
              sectionId: section.id,
              sectionName: section.name,
            });
          });
        });
      }

      if (irregularStudents.length > 0) {
        irregularStudents.map((irregular) => {
          studentsEnrolled.push({
            ...irregular,
            sectionId: "irregular",
            sectionName: "Irregular",
          });
        });
      }

      if (studentsEnrolled.length > 0) {
        studentsEnrolled.map((studentEnrolled) => {
          if (studentEnrolled.assessments.length > 0) {
            filteredStudents.push(studentEnrolled);
          }
        });
      }

      if (this.filterStudentsBySection) {
        filteredStudents = filteredStudents?.filter(
          (student) => student.sectionId == this.filterStudentsBySection
        );
      }

      return filteredStudents;
    },

    sections() {
      let sectionOptions = [],
        sections =
          this.subjectData.sections?.length > 0
            ? [...this.subjectData.sections]
            : [],
        irregularStudents =
          this.subjectData.irregular?.length > 0
            ? [...this.subjectData.irregular]
            : [];

      if (sections.length > 0) {
        sections.map((section) => {
          sectionOptions.push({
            text: section.name,
            value: section.id,
          });
        });
      }

      if (irregularStudents.length > 0) {
        sectionOptions.push({
          text: "Irregular",
          value: "irregular",
        });
      }

      return sectionOptions;
    },
  },
  async mounted() {
    const subjectId = this.subject.id,
      assessmentId = this.$route.query.assessmentId,
      selection = this.$route.query.selection;

    await this.$store.dispatch("assessmentModule/getAssessments", subjectId);
    if (assessmentId) {
      await this.selectAssessment(subjectId, assessmentId);

      if (selection) {
        await this.selectStudent(selection);
      }
    }
  },
  methods: {
    moment: moment,
    async updateStudentPoints(studentPoints) {
      if (studentPoints.length > 0) {
        let groupStudentPoints = _.chain(studentPoints)
          .groupBy("studentAssessmentId")
          .map((value, key) => ({
            studentAssessmentId: key,
            logs: value,
          }))
          .value();

        await this.$store.dispatch(
          "assessmentModule/updateStudentPoints",
          groupStudentPoints
        );

        this.logsDialog = false;
      } else {
        notify({
          title: "Info",
          status: "info",
          message: "Nothing to update",
        });
      }
    },
    downloadFile(fileUrl, fileName) {
      //create an xml http request and allow cors in firebase to download
      var xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.open("GET", fileUrl, true);
      xhr.send();
      xhr.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          const downloadUrl = window.URL.createObjectURL(this.response);
          const anchor = document.createElement("a");
          anchor.href = downloadUrl;
          anchor.download = fileName;
          document.body.appendChild(anchor);
          anchor.click();
        }
      };
    },
    getAllowedPoint(enumerateCount, totalPoints, studentPoint) {
      let allowedPoint = totalPoints;

      if (enumerateCount) {
        allowedPoint = totalPoints / enumerateCount;
      }

      return studentPoint <= allowedPoint
        ? true
        : `Please input a maximum of ${allowedPoint}`;
    },
    setStudentPoint(
      testIndex,
      questionIndex,
      studentAnswerIndex,
      logIndex,
      point
    ) {
      let studentAnswers = [
          ...this.selectedStudent.assessmentTests[testIndex].questions[
            questionIndex
          ].studentAnswers,
        ],
        logs = [...studentAnswers[studentAnswerIndex].logs];

      logs[logIndex] = {
        ...logs[logIndex],
        points: point,
      };

      if (this.studentPoints.length > 0) {
        let pointIndex = null;
        this.studentPoints.find((studentPoint, index) => {
          if (studentPoint.id == logs[logIndex].id) {
            pointIndex = index;
          }
        });

        if (pointIndex !== null) {
          this.studentPoints[pointIndex] = {
            ...this.studentPoints[pointIndex],
            id: logs[logIndex].id,
            studentAssessmentId: studentAnswers[studentAnswerIndex].id,
            points: point,
          };
        } else {
          this.studentPoints.push({
            id: logs[logIndex].id,
            studentAssessmentId: studentAnswers[studentAnswerIndex].id,
            points: point,
          });
        }
      } else {
        this.studentPoints.push({
          id: logs[logIndex].id,
          studentAssessmentId: studentAnswers[studentAnswerIndex].id,
          points: point,
        });
      }

      studentAnswers[studentAnswerIndex] = {
        ...studentAnswers[studentAnswerIndex],
        logs,
      };

      this.selectedStudent.assessmentTests[testIndex].questions[
        questionIndex
      ].studentAnswers = studentAnswers;
    },
    getText(assessmentId, assessmentOptions) {
      let text = "";

      if (assessmentOptions.length > 0 && assessmentId) {
        text = assessmentOptions.find(
          (option) => option.value == assessmentId
        ).text;
      }

      return text;
    },
    getScore(studentAssessments, recordHighest) {
      let score = "N/A",
        studentScores = [];

      studentAssessments?.map((assessment) => {
        if (assessment.score !== null) {
          studentScores.push(assessment.score);
        }
      });

      if (studentScores.length > 0) {
        if (recordHighest) {
          score = Math.max(...studentScores);
        } else {
          score = studentScores.at(-1);
        }
      }

      return score;
    },
    async selectStudent(selection) {
      this.studentPoints = [];
      this.selectedStudent = {
        hasLogs: false,
        firstName: selection.firstName,
        lastName: selection.lastName,
      };

      let payload = {
        assessmentId: this.selectedAssessment,
        studentId: selection.id,
      };

      const getLogs = await this.$store.dispatch(
        "assessmentModule/getAssessmentGrade",
        payload
      );

      if (getLogs) {
        let assessmentGrade = { ...this.assessmentGrade };

        this.selectedStudent = await this.setupAssessment({
          ...assessmentGrade,
          firstName: selection.firstName,
          lastName: selection.lastName,
        });
      }

      if (this.selectedStudent.hasLogs) {
        await this.mapImages(this.selectedStudent);
      }
      this.logsDialog = true;
    },
    async selectAssessment(subjectId, assessmentId) {
      this.selectedAssessment = null;
      this.loading = true;
      let payload = {
        subjectId,
        assessmentId,
      };

      await this.$store.dispatch(
        "_subjectsModule/getSubjectTaskGradeById",
        payload
      );

      this.loading = false;
      this.selectedAssessment = assessmentId;
    },
    async mapImages(assessment) {
      let tests = [...assessment.assessmentTests];
      tests.map((test, testIndex) => {
        let questions = [...test.questions];
        questions.map(async (question, questionIndex) => {
          if (question.image) {
            assessment.assessmentTests[testIndex].questions[
              questionIndex
            ].preview = await this.$store.dispatch(
              "assessmentModule/getFileUrl",
              question.image
            );
          } else {
            assessment.assessmentTests[testIndex].questions[
              questionIndex
            ].preview = null;
          }

          let choices = [...question.choices],
            studentChoices = [...question.studentAnswers];
          if (test.imageChoices) {
            choices.map(async (choice, choiceIndex) => {
              assessment.assessmentTests[testIndex].questions[
                questionIndex
              ].choices[choiceIndex].preview = await this.$store.dispatch(
                "assessmentModule/getFileUrl",
                choice.choice
              );
            });
            studentChoices.map((studentChoice, studentChoiceIndex) => {
              let logs = [...studentChoice.logs];
              logs.map(async (log, logIndex) => {
                assessment.assessmentTests[testIndex].questions[
                  questionIndex
                ].studentAnswers[studentChoiceIndex].logs[logIndex].preview =
                  await this.$store.dispatch(
                    "assessmentModule/getFileUrl",
                    log.answer
                  );
              });
            });
          } else {
            if (test.test === "image" || test.test === "file") {
              studentChoices.map((studentChoice, studentChoiceIndex) => {
                let logs = [...studentChoice.logs];
                logs.map(async (log, logIndex) => {
                  assessment.assessmentTests[testIndex].questions[
                    questionIndex
                  ].studentAnswers[studentChoiceIndex].logs[logIndex].preview =
                    await this.$store.dispatch(
                      "assessmentModule/getFileUrl",
                      log.answer
                    );
                });
              });
            }

            choices.map(async (choice, choiceIndex) => {
              assessment.assessmentTests[testIndex].questions[
                questionIndex
              ].choices[choiceIndex].preview = null;
            });
          }
        });
      });
    },
    sanitizeDate(date) {
      let dateTime = null;
      if (date) {
        let sanitizedDate = moment(date).format("YYYY-MM-DD hh:mm A");

        dateTime = sanitizedDate;
      }

      return dateTime;
    },
    setupAssessment(assessmentData) {
      let assessment = {
          ...assessmentData,
          from: this.sanitizeDate(assessmentData.from),
          to: this.sanitizeDate(assessmentData.to),
        },
        hasLogs = true;
      delete assessment.createdAt;
      let studentAssessments = [];
      assessment.studentAssessments?.map((studentAssessment) => {
        if (studentAssessment.assessmentLogs.length > 0) {
          studentAssessments.push(studentAssessment);
        }
      });

      if (studentAssessments.length > 0) {
        let tests = assessment.assessmentTests?.map((test) => {
          let method = this.testOptions["AUTOMATIC"].find(
            (type) => type.value == test.test
          )
            ? "AUTOMATIC"
            : "MANUAL";
          let questions = test.questions.map((question) => {
            let preview = null,
              answers = [{ answer: "" }],
              choices = [
                {
                  choice: "",
                  correct: true,
                  preview: null,
                },
                {
                  choice: "",
                  correct: false,
                  preview: null,
                },
                {
                  choice: "",
                  correct: false,
                  preview: null,
                },
              ],
              studentAnswers = [];

            studentAssessments.map((studentAssessment) => {
              let logs = [];
              studentAssessment.assessmentLogs.map((log) => {
                if (log.questionId == question.id) {
                  logs.push({ ...log, preview: null });
                }
              });
              studentAnswers.push({
                ...studentAssessment,
                dateStart: this.sanitizeDate(studentAssessment.dateStart),
                dateEnd: this.sanitizeDate(studentAssessment.dateEnd),
                logs: [...logs],
              });
            });

            if (method === "AUTOMATIC") {
              if (test.test === "multiplechoice") {
                choices = question.choices.map((choice) => {
                  let preview = null;

                  choice = { ...choice, preview };
                  return choice;
                });
              } else {
                answers = question.answers;
              }
            }

            question = {
              ...question,
              preview,
              answers,
              choices,
              studentAnswers,
            };

            return question;
          });

          test = {
            ...test,
            totalItems: questions.length,
            questions,
            method,
            expand: true,
          };
          return test;
        });

        delete assessment.studentAssessments;
        return { ...assessment, assessmentTests: tests, hasLogs };
      } else {
        delete assessment.studentAssessments;
        return { ...assessment, assessmentTests: {}, hasLogs: false };
      }
    },
    intToChar(int) {
      const code = "a".charCodeAt(0);

      return String.fromCharCode(code + int);
    },
    testType(method, value) {
      let type = this.testOptions[method].find((type) => type.value == value);
      return type.text;
    },
    ucFirst(value) {
      return value.charAt(0).toUpperCase() + value.substring(1);
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
</style>
